// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from 'context/header';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import MainServices from 'components/promptService/mainServices';
import JointComissionInternational from 'components/JointCommission';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  display: flex;
  flex-direction: column;

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    margin-top: 0;
    align-items: center;
  }
`;

const GridMainServices = styled.div`
  margin-bottom: 4.375rem;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 0 1.875rem 0;
    border-radius: 8px;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('11.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'}>
          {/* About */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckner44cggu5r0b25vy1ooarv', true)}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            containerPaddingDesk="3.125rem 0 1.5rem 0"
            containerPaddingLaptop="2.125em 0 0.4rem 0"
            infusion
            infusionAdjustBulletPoint
            infPadding
            contentTagUlListStyleType="none"
            isMarginLeftPromptService
          />
          {/* About */}
          <JointComissionInternational />
          {/* Scheduling */}
          <PromptServiceContent
            markdown={getMarkdown(page, 'cknernbpcgza40d16d1ak94b9', true)}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            infusion
            contentTagUlListStyleType="none"
            isMarginLeftPromptService
            infusionAdjustBulletPoint
          />
          {/* Scheduling */}
        </Part>
        <GridMainServices>
          <Part gridColumn={'2 / -2'}>
            <MainServices
              markdown={getMarkdown(page, 'cknerwr7kgx2g0d75xge9k6ht', true)}
              infusion
            />
          </Part>
        </GridMainServices>
      </GridContainer>
    </>
  );
};

const InfusionCenter = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckneqs15sguv40b758za8a3yr', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckneq7dc0gnet0b25hxdpbn0i" }) {
                featuredMarketing(locales: [pt_BR, en, es]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <InfusionCenter page={response.gcms.site.pages} />;
      }}
    />
  );
};
