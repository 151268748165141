// Libs
import React from 'react';
import styled from 'styled-components';

// Images
import Logo from 'images/logos/joint comission@2x.png';

// Components
import { device } from './device';

// Styles
const Container = styled.section`
  grid-area: OUT;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.laptop} {
    margin-top: 0;
  }
`;

const AllServices = styled.div`
  padding: 1.875rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5rem;
  background: #f4f5f7;

  @media ${device.laptop} {
    padding: 1.25rem;
    margin-bottom: 1.875rem;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const ContainerText = styled.div`
  justify-content: center;

  > p {
    width: 75%;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    margin-left: 4.6875rem;
    line-height: 1.375rem;

    @media ${device.laptop} {
      width: fit-content;
      margin-left: 0;
    }

    > b {
      margin-left: 0;
    }
  }
`;

const BoxLogo = styled.figure`
  width: 14%;
  margin-left: 2.75rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
    margin-left: 0;
  }

  @media ${device.mobile} {
    width: 59%;
  }

  @media ${device.mobile375} {
    width: 62%;
  }

  @media ${device.mobile320} {
    width: 78%;
  }
`;

const LogoJoint = styled.img`
  width: 5rem;

  @media ${device.laptop} {
    width: 3.75rem;
  }
`;

const JointComissionInternational = () => {
  return (
    <Container>
      <AllServices>
        <BoxLogo>
          <LogoJoint src={Logo} alt="Joint Commission International (JCI)" />
        </BoxLogo>
        <ContainerText>
          <p>
            <b>Joint Commission International (JCI)</b>A principal acreditação
            no âmbito global sobre a segurança e a qualidade dos serviços de
            saúde. Essa acreditação é concedida por uma organização não
            governamental norte-americana que leva esse nome e se dedica à
            certificação de padrões de qualidade em serviços de saúde desde
            1953.
          </p>
        </ContainerText>
      </AllServices>
    </Container>
  );
};

export default JointComissionInternational;
